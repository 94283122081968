import * as React from "react"

// styles
const pageStyles = {
    color: "#232129",
    padding: "96px",
    fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
    marginTop: 0,
    marginBottom: 64,
    maxWidth: 320,
}

// markup
const NotFoundPage = () => {
    setTimeout(() => {
        window.location.replace("/")
    }, 1000)
    return (
        <main style={pageStyles}>
            <title>Not found</title>
            <h1 style={headingStyles}>Page not found, being redirect to homepage</h1>
        </main>
    )
}

export default NotFoundPage
